import React, { useState, useMemo, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Loading from "./LoadingAnimation";
import InfoTooltip from "./InfoTooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const QueryFilterTabChart = ({
  data = [],
  isLoaded = true,
  error = null,
  title = "Subcluster Analysis",
  tooltipText = null,
  kind = "bar",
}) => {
  // Get unique cluster names for tabs
  const uniqueClusterNames = useMemo(() => {
    return [...new Set(data.map((q) => q.cluster_name))];
  }, [data]);

  // State for selected cluster
  const [selectedCluster, setSelectedCluster] = useState(null);

  // Update selected cluster when data is loaded
  useEffect(() => {
    if (isLoaded && uniqueClusterNames.length > 0) {
      setSelectedCluster((prevSelected) =>
        !prevSelected || !uniqueClusterNames.includes(prevSelected)
          ? uniqueClusterNames[0]
          : prevSelected
      );
    }
  }, [isLoaded, uniqueClusterNames]);

  // Filter subclusters based on selected cluster
  const filteredSubclusters = useMemo(() => {
    if (!isLoaded || error) {
      return [];
    }

    const effectiveCluster = selectedCluster || uniqueClusterNames[0];
    return data.filter((q) => q.cluster_name === effectiveCluster);
  }, [data, selectedCluster, isLoaded, error, uniqueClusterNames]);

  const chartData = {
    labels: filteredSubclusters.map((item) => item.subcluster_name),
    datasets: [
      {
        data: filteredSubclusters.map((item) => item.count),
        backgroundColor: [
          "#2b86b3",
          "#3db0cb",
          "#64d9dd",
          "#2e366c",
          "#2c5d93",
          "#FFCE56",
          "#4BC0C0",
          "#FF9F40",
          "#FF6384",
        ],
        borderRadius: kind === "bar" ? 4 : 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: kind === "donut",
        position: "right",
        labels: {
          boxWidth: 10,
          font: { size: 10 },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.formattedValue}`,
        },
      },
    },
    ...(kind === "bar" && {
      scales: {
        x: {
          grid: { display: false },
          ticks: { font: { size: 10 } },
        },
        y: {
          beginAtZero: true,
          grid: { color: "#f0f0f0" },
          ticks: { font: { size: 10 } },
        },
      },
    }),
  };

  const ChartComponent = kind === "bar" ? Bar : Doughnut;

  return (
    <div className="container-fluid p-0" style={{ backgroundColor: "#F5F5F5" }}>
      <div className="row">
        {/* Left side vertical tabs */}
        <div className="col-4 pe-0" style={{ border: "5px" }}>
          <h6 className="fw-bold ps-3" style={{ paddingTop: "20px" }}>
            Query Groups (Clusters)
          </h6>
          <div className="nav flex-column nav-pills" role="tablist">
            {uniqueClusterNames.map((clusterName) => (
              <button
                key={clusterName}
                className="nav-link text-start border-0 rounded-0"
                onClick={() => setSelectedCluster(clusterName)}
                disabled={!isLoaded || error}
                style={{
                  backgroundColor:
                    selectedCluster === clusterName ? "#fff" : "transparent",
                  color: "#666",
                  opacity: !isLoaded || error ? 0.5 : 1,
                  padding: "10px 15px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              >
                {clusterName}
              </button>
            ))}
          </div>
        </div>

        {/* Right side chart content */}
        <div className="col-8 ps-0">
          <div
            style={{
              backgroundColor: "#F5F5F5",
              padding: "15px",
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
            }}
          >
            <p className="fw-bold mb-3">
              {title} {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
            </p>
            {error ? (
              <p>Sorry! An error occurred while fetching the data.</p>
            ) : !isLoaded ? (
              <Loading />
            ) : data.length === 0 ? (
              <p>No data available</p>
            ) : (
              <div style={{ flex: 1, minHeight: "300px" }}>
                <ChartComponent data={chartData} options={options} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryFilterTabChart;
